import React from "react"
import { toast } from "react-toastify";
import * as Yup from 'yup'
import API from "../../../../firebase/api";
import Form from '../../../Common/Form/Form'

export default ({account}) => {

  const validationSchema = Yup.object().shape({
    paypal: Yup.string()
      .email('Invalid paypal address.')
      .required('Paypal is required'),
      username: Yup.string()
      .required('Username is required'),
  });

  async function onSubmit(values){
        let response = await API.setReferralAccount({...values})
        if(response){
            toast.success('Saved.')
        }
  }

  return (
    <Form validationSchema={validationSchema} onSubmit={onSubmit} initialValues={{
        username: account ? account.username : "",
        paypal: account ? account.paypal : ""
    }} fields={[
            {
                placeholder: 'user123',
                label: "Username",
                type: 'text',
                name: 'username',
                info: {
                    text: `Username is used as your unique identifier to assign sign-ups and user purchases to your account.`,
                }
            },
            {
                placeholder: 'email@paypal.com',
                label: "Paypal",
                type: 'email',
                name: 'paypal',
                info: {
                    text: `Will be used for payouts.`,
                }
            }
        ]} 
        submitButtonText={'Save'}
        />
  )
}