import React, { useEffect, useState } from "react";
import API from "../../../../firebase/api";
import Layout from "../../../layout";
import SideMenu from "../AccountSideMenu/AccountSideMenu";
import Form from "./Form";
import * as styles from "./referral.module.scss";

export default () => {
  const [account, setAccount] = useState(null);
  const [recentWeeks, setRecentWeeks] = useState([]);
  const [payouts, setPayouts] = useState([]);

  useEffect(() => {
    // Check if account is registered for referrals
    (async () => {
      let acc = await API.getReferrals();
      setAccount(acc);
      if (acc) {
        setRecentWeeks(await API.getLastWeeksReferrals());
        setPayouts(await API.getReferralsPayouts());
      }
    })();
  }, []);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.left}>
          <SideMenu />
        </div>
        <div className={styles.right}>
          <h1>Referrals</h1>
          {account ? (
            <p className={styles.info}>
              Paste this URL to earn through our referral campaign. <br />
              <span>
                https://previewed.app?via=
                {account ? account.username : "username"}
              </span>
              <br />
              You can also send users to any other landing page or mockup
              template pages e.g.
              <br />
              <span>
                https://previewed.app/mockups/apple/iphone?via=
                {account ? account.username : "username"}
              </span>
            </p>
          ) : null}
          <h2>Account</h2>
          <Form account={account} />
          <h2>Recent Conversions</h2>
          <p>Referral statistics & performance for the last 4 weeks.</p>
          <table>
            <thead>
              <tr>
                <th>Week Starting</th>
                <th>Number of users</th>
                <th>Number of checkouts</th>
                <th>Estimated earnings</th>
              </tr>
            </thead>
            <tbody>
              {recentWeeks.map((data) => (
                <tr>
                  <td>
                    {new Date(data.week_starting).toLocaleString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </td>
                  <td>{data.num_signups}</td>
                  <td>{data.num_checkouts}</td>
                  <td>${data.estimated_earnings}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!recentWeeks.length ? (
            <p>Your conversions will appear here.</p>
          ) : null}
          <h2>Payouts</h2>
          <p>
            Payouts are finalized on a monthly basis, and paid out quarterly
            automatically to your preferred payment method.{" "}
          </p>
          <table>
            <thead>
              <tr>
                <th>Period Start</th>
                <th>Period End</th>
                <th>Earnings</th>
                <th>Payout Status</th>
              </tr>
            </thead>
            <tbody>
              {payouts.map((data) => (
                <tr>
                  <td>
                    {new Date(data.payout_period_start).toLocaleString(
                      "en-US",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }
                    )}
                  </td>
                  <td>
                    {new Date(data.payout_period_end).toLocaleString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </td>
                  <td>${data.payout_amount}</td>
                  <td>{data.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!payouts.length ? <p>Your payouts will appear here.</p> : null}
        </div>
      </div>
    </Layout>
  );
};
